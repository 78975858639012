import { createRouter, createWebHistory } from 'vue-router'
const HomeView = () => import("@/views/home/HomeView.vue")
const DailyView = () => import("@/views/daily/DailyView.vue")
const ProgramView = () => import("@/views/program/ProgramView.vue")
const NotFound = () => import("@/views/error/NotFound.vue")
const BlogView = () => import("@/views/blog/look/BlogView.vue")
const BlogUpdateView = () => import("@/views/blog/update/BlogUpdateView.vue")
const AdminLoginView = () => import("@/views/admin/login/AdminLoginView.vue")
const SkillTreeView = () => import("@/views/skill/tree/SkillTreeView")
const CourseView = () => import("@/views/course/CourseView.vue")
const FootprintView = () => import("@/views/footprint/FootprintView.vue")
const BlogAddView = () => import("@/views/blog/add/BlogAddView.vue")
const AccessView = () => import("@/views/access/AccessView")
const ToDoView = () => import("@/views/todo/ToDoView")
const ToDoUpdateList = () => import("@/views/todo/ToDoUpdateList")
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/daily/',
    name: 'daily',
    component: DailyView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/program/',
    name: 'program',
    component: ProgramView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/blog/:blogid',
    name: 'blog',
    component: BlogView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/blog/update/:blogid',
    name: 'blog_update',
    component: BlogUpdateView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/admin/login/',
    name: 'admin_login',
    component: AdminLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/skill/tree/',
    name: 'skill_tree',
    component: SkillTreeView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/course/',
    name: 'course',
    component: CourseView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/footprint/',
    name: 'footprint',
    component: FootprintView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/blog/add/:blogtypeid',
    name: 'blog_add',
    component: BlogAddView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/access/',
    name: 'access',
    component: AccessView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/todo/',
    name: 'todo',
    component: ToDoView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/todo/updatelist/',
    name: 'todo_updatelist',
    component: ToDoUpdateList,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/404/",
    name: "404",
    component: NotFound,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && !store.state.user.is_login) {
    next({ name: "404" });
  } else {
    next();
  }
})

export default router
