
export default {
  state: {
    q: [
        // {
        //     text: "",
        //     type: "" // 包含 通知，警告，错误
        // },
    ],
  },
  getters: {
  },
  mutations: {
    addNotice(state, notice) {
        state.q.push(notice);
    },

    popNotice(state) {
        state.q.shift();
    }
  },
  actions: {
    addNotice(context, notice) {
        notice.opacity = 75;
        context.commit("addNotice", notice);
        setTimeout(function(){notice.opacity = 50},3000);
        setTimeout(function(){context.commit("popNotice")},5000);
    },
    popNotice(context) {
        context.commit("popNotice")
    }
  },
  modules: {
  }
}
