<template>
    <div id="colophon" class=" border-top text-center py-4 mt-4">
        <p>
            <a class="text-decoration-none my-cursor-p" href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2023010663号</a>
        </p>
    </div>
</template>

<script>
export default {
    name: "ColophonView" // 底部备案号
} 
</script>

<style scoped>

</style>