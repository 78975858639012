<template>
  <NavbarView />
  <router-view class="min-vh-100" />
  <ColophonView /> 
  <NoticeQueueView />
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import NavbarView from '@/components/NavbarView.vue'
import ColophonView from '@/components/ColophonView.vue';
import NoticeQueueView from './components/notice/NoticeQueueView.vue';

export default {
  name: 'App',
  components: {
    NavbarView,
    ColophonView,
    NoticeQueueView,
  }
}
</script>

<style>

.my-cursor-p {
  color: #000;
}
.my-cursor-p:hover {
    color: rgb(11, 142, 185);
    fill: rgb(11, 142, 185);
    cursor: pointer;
}
.my-cursor-p-r:hover {
    color: rgb(192, 0, 0);
    cursor: pointer;
}

</style>
