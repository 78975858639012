<template>
    <div class="notice-queue col-2 position-fixed end-0 z-3">
        <div role="alert" v-for="(notice, i) in store.state.noticeQueue.q" :key="i" :class="'alert alert-dismissible fade show p-2 ' + getColor(notice.type) + ' opacity-' + notice.opacity">
            <strong>{{ notice.text }}</strong>
            <button type="button" class="btn-close p-2 mt-1" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
    name: "NoticeQueueView", // 通知队列
    setup() {
        let store = useStore();
        let typeColor = {
            "警告": "alert-warning",
            "通知": "alert-primary",
            "错误": "alert-danger",
        }

        const getColor = (type) => {
            return typeColor[type]
        }

        return {
            store,
            getColor
        }
    }
}
</script>

<style scoped>
.notice-queue {
    top: 9vh;
}
</style>
