<template>
    <div class="banner">
        <div class="banner-back">
            <div class="banner-back-img">

                <img src="https://www.superpea.top/static/image/blog/back/wandering/back-zhou.jpg" alt="">
                <div class="banner-back-text">
                    面对恐惧&nbsp;总会有人&nbsp;拥有&nbsp;跨越历史&nbsp;当下&nbsp;和未来的勇气
                </div>
            </div>
            <img class="banner-back-character" src="https://www.superpea.top/static/image/blog/back/wandering/zhou-p1.png"
                alt="">
            <div class="banner-back-content">
                <p>我相信</p>
                <p>会再次看到蓝天</p>
                <p>鲜花</p>
                <p>挂满枝头</p>
            </div>
        </div>

        <div class="countdown">
            <p>距离流浪地球3上映</p>
            <div class="countdown-number">
                <p>还剩<span>{{ countdown_day }}</span>天</p>
                <p class="countdown-english">
                    <span>DISTANCE </span>FROM WANDERING EARTH 3<span> IS RELEASED</span><br />
                    {{ countdown_day }} DAYS LEFT
                </p>
            </div>
        </div>
        <div class="banner-icon">
            <img class="w-100 h-100" src="https://www.superpea.top/static/image/blog/ico/bigfavicon.png" alt="">
        </div>
        <div class="wandering-logo">
            <img src="https://www.superpea.top/static/image/blog/back/wandering/Wandering-Earth-3.png" alt="">
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: "BannerView", // 首页头
    setup() {
        let icon_top = -0.04;
        let icon_right = 0;
        let countdown_day = ref(0);


        onMounted(() => {
            $(".banner-back-content p").hide();

            $(window).on("scroll", function () {
                if ($(document).scrollTop() <= $(window).height() * 1) {
                    let proportion = $(document).scrollTop() / $(window).height();
                    let icon_pro = 0.6;
                    $('.banner-icon').css({
                        'top': (icon_top + 1.4 * Math.min(icon_pro, proportion)) * 100 + 'vh',
                        'right': ((icon_right + (icon_right + 1.3) * Math.min(icon_pro, proportion))) * 100 + 'vw',
                        'transform': `translate(0, 0) scale(${1 - 0.8 * Math.min(icon_pro, proportion)})`
                    });
                    $('.banner-back-img').css({
                        'top': proportion * 100 + "%"
                    });
                    $(".banner-back-content p").hide();
                    $(".banner-back-content p").eq(parseInt(proportion / 0.8 * $(".banner-back-content p").length)).show();
                }
            });
        });

        onUnmounted(() => {
            $(window).off("scroll");
        });

        const get_countdown_day = () => {
            let endTime = new Date("2027-2-6 8:0:0");
            let nowTime = new Date();
            let t = endTime.getTime() - nowTime.getTime();
            countdown_day = Math.floor(t / 1000 / 60 / 60 / 24);
        }
        get_countdown_day();

        return {
            countdown_day
        }
    }
}
</script>

<style scoped>
.banner {
    user-select: none;
}

.banner {
    width: 100%;
    height: 100vh;
    /* background-image: url('https://www.superpea.top/static/image/blog/back/m.jpg'); */
    background-color: #060E07;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
}

.banner-back {
    width: 100%;
    height: 100%;
    position: relative;
}

.banner-back .banner-back-img {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0%;
}

.banner-back .banner-back-img img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    clip-path: polygon(0% 30%, 100% 13%, 100% 73%, 0% 90%);
}

.banner-back-img .banner-back-text {
    position: absolute;
    top: 30vh;
    left: 3vw;
    font-size: 3.5vh;
    color: #fff;
    transform: rotate(-4deg);
    display: none;
}

.banner-back-text p {
    margin-bottom: 0.5vh;
}

.banner-back .banner-back-character {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 75vh;
    z-index: 999;
}

.banner-back .banner-back-content {
    position: absolute;
    bottom: 6vh;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: 1000;
    color: #fff;
    z-index: 1000;
    text-align: center;
    display: none;
}

.banner-back-blur-top {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100vw;
    background-color: transparent;
    border-bottom: 10vh solid transparent;
    border-top: 20vh solid #060E07;
    border-left: 100vw solid #060E07;
}

.banner-back-blur-bot {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100vw;
    background-color: transparent;
    border-top: 10vh solid transparent;
    border-bottom: 20vh solid #060E07;
    border-right: 100vw solid #060E07;
}

.banner-icon {
    width: 20vh;
    position: absolute;
    top: -4vh;
    right: 0vh;
    transform: translate(0, 0);
    filter: drop-shadow(10px -5px 10px #666);
    animation: banner-icon-animate 0.6s linear;
    z-index: 50;

}

@keyframes banner-icon-animate {
    0% {
        top: -12vh;
        right: -15vh;
    }

    100% {
        top: -4vh;
        right: 0vh;
    }
}


.countdown p {
    padding: 0;
    margin: 0;
}

.countdown {
    position: absolute;
    bottom: 1vh;
    right: 1vh;
    z-index: 9999;
    color: #fff;
    font-size: 2.6vh;
    text-shadow: 2px 2px 1px #0E1213;
}

.countdown-number {
    border-left: 3px solid #E82811;
    padding-left: 0.5vh;
    margin-left: 16.5vh;
    position: relative;
    padding-top: 4vh;
}

.countdown-number p:nth-child(1) span {
    font-size: 7vh;
    font-weight: 1000;
    color: #F01C00;
}

.countdown-number p:nth-child(2) span {
    display: none;
}

.countdown-number>p:nth-child(1) {
    margin-top: -9.8vh;
}

.countdown .countdown-english {
    margin-top: -2vh;
    font-size: 1vh;
}

.wandering-logo {
    position: absolute;
    top: 2vh;
    right: 18vh;
    height: 12vh;

}

.wandering-logo img {
    height: 100%;
    width: auto;
}

@media screen and (min-width: 640px) {
    .banner {
        /* background-image: url('https://www.superpea.top/static/image/blog/back/pc.jpg'); */

    }

    .countdown {
        bottom: 2vh;
        right: 2vh;
        font-size: 5vh;
    }

    .countdown-number {
        border-left: 4px solid #E82811;
        padding-left: 1.4vh;
        margin-left: 31.2vh;
        position: relative;
        padding-top: 2.4vh;
    }

    .countdown-number p:nth-child(1) span {
        font-size: 10vh;
    }

    .countdown-number p:nth-child(2) span {
        display: inline;
    }
}

@media screen and (min-width: 800px) {
    .banner-back-img .banner-back-text {
        display: block;
    }

    .banner-back .banner-back-img img {
        clip-path: polygon(0% 40%, 100% 23%, 100% 63%, 0% 80%);
    }

    .banner-back .banner-back-content {
        display: block;
    }
}
</style>