import $ from 'jquery'
// import useStore from 'vuex'
export default {
    state: {
        refresh: "",
        access: "", 
        is_login: false,
        AccessTimer: null,
    },
    getters: {
    },
    mutations: {
        login(state, data) {
            state.refresh = data.refresh;
            state.access = data.access;
            state.is_login = true;  
        },
        updateRefresh(state, refresh) {
            state.refresh = refresh;
        },
        updateAccess(state, access) {
            state.access = access;
        },
        update_is_login(state) {
            state.is_login = true;
        },
        logout(state) {
            state.refresh = "";
            state.access = "";
            state.is_login = false;
        },
        updateAccessTimer(state, Timer) {
            clearInterval(state.AccessTimer);
            state.AccessTimer = Timer;
        },
        removeAccessTimer(state) {
            clearInterval(state.AccessTimer);
            state.AccessTimer = null;
        }
    },
    actions: {
        login(context, data) {
            $.ajax({
                url: "https://www.superpea.top/blogapp/token/",
                type: "post",
                data: {
                    username: data.username,
                    password: data.password,
                },
                success: resp => {
                    localStorage.setItem("jwt_refresh_token", resp.refresh);
                    context.dispatch("is_login");
                    data.success(resp);
                },
                error: resp => {
                    data.error(resp);
                }
            })
        },
        is_login(context) {
            let jwt_refresh_token = localStorage.getItem("jwt_refresh_token");
            if (jwt_refresh_token) {
                $.ajax({
                    url: "https://www.superpea.top/blogapp/token/refresh/",
                    type: "post",
                    data: {
                        refresh: jwt_refresh_token,
                    },
                    success: resp => {
                        context.commit("login", {
                            refresh: jwt_refresh_token,
                            access: resp.access,
                        });
                        context.commit("updateAccessTimer", setInterval(() => {
                            context.dispatch("refreshAccess");
                        }, 270000))
                    },
                    error: () => {
                        localStorage.removeItem("jwt_refresh_token");
                    }
                })
            }
        },
        logout(context) {
            localStorage.removeItem("jwt_refresh_token");
            context.commit("logout");
            context.commit("removeAccessTimer");
        },
        refreshAccess(context) {
            $.ajax({
                url: "https://www.superpea.top/blogapp/token/refresh/",
                type: "post",
                data: {
                    refresh: context.state.refresh,
                },
                success: resp => {
                    context.commit("updateAccess", resp.access);
                },
                error: () => {
                    context.dispatch("logout");
                }
            })
        }

    },
    modules: {
    }
  }
  